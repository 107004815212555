import React, { Component } from 'react'
import StudyViewModel from '../../../Models/Study/ViewModel'
import CircularProgress from '@material-ui/core/CircularProgress'
import { observer } from 'mobx-react'
import dateFormat from 'dateformat'
import FilterComponent from '../../Variable/FilterComponent'
import { Table, Column, Cell } from 'fixed-data-table-2'
import './table.css'
import ListModel from '../../../Models/Variable/ListModel'
import ReactTooltip from 'react-tooltip'
import { parse } from 'json2csv'
import { firebaseApp } from 'src/config/firebase'
import userModel from '../../../Models/UserModel'
import BatchesModel from '../../../Models/Batches/BatchesModel'
import { Button, Segment } from 'semantic-ui-react'
import BatchesVariableModel from '../../../Models/Batches/BatchesVariableModel'
import CheckboxCell from '../../../Features/RecordsSelectToDelete/CheckboxCell'
import RecordsSelectToDeleteStore from '../../../Features/RecordsSelectToDelete/store'
import FixedBox from '../../../Features/RecordsSelectToDelete'
import { history } from 'src/config/routing/history'

/**
 * Types of sorting.
 *
 * @type {{ASC: string, DESC: string}}
 */
const SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
}

/**
 * Cell to show text information in the table.
 */
@observer
class TextCell extends Component {
  handleRecordClick(studyID, recordID) {
    history.push(`/studies/${studyID}/record/${recordID}`)
  }

  handleBatchClick(studyID, batchID) {
    history.push(`/studies/${studyID}/batch/${batchID}`)
  }

  handleShareRecordClick(shareId, recordID) {
    history.push(`/studies-share/${shareId}/record/${recordID}`)
  }

  handleShareBatchClick(shareId, batchID) {
    history.push(`/studies-share/${shareId}/batch/${batchID}`)
  }

  render() {
    const {
      study,
      rowIndex,
      field,
      data,
      isBatchFields,
      fetchData,
      studyData,
      batchesList,
      ...props
    } = this.props
    const object = data.getObjectAt(rowIndex)
    let value = ''

    if (field === 'Rec ID') {
      value = object['recordId']
    } else if (field === 'SUID') {
      value = studyData.suid
    } else if (field === 'Count') {
      value = object.count
    } else if (field === 'orderId') {
      value = object.orderId
    } else if (field === 'Record Time') {
      value = dateFormat(object.time, 'hh:MM:ss')
    } else if (field === 'Record Date') {
      value = dateFormat(object.time, 'dd mmmm yyyy')
    } else if (field === 'Study name') {
      value = studyData.name
    } else if (field === 'Batch') {
      const batchesItem = batchesList.find(
        (item) => item.buid.toString() === object.batchId.toString()
      )
      if (batchesItem) {
        value = batchesItem.name
      }
    } else {
      value = object.values[field]
    }

    if (object.isNew) {
      setTimeout(() => {
        this.cellRef.style.backgroundColor = '#80b4ff'
      }, 50)

      setTimeout(() => {
        this.cellRef.style.backgroundColor = 'initial'
      }, 500)
    }

    return (
      <div
        ref={(input) => {
          this.cellRef = input
        }}
        className="new_cell"
      >
        <Cell
          {...props}
          onMouseEnter={() => {
            ReactTooltip.show()
          }}
          onMouseLeave={() => {
            ReactTooltip.hide()
          }}
          onClick={() => {
            if (fetchData.shareId) {
              if (isBatchFields) {
                this.handleShareBatchClick(fetchData.shareId, object.batchId)
              } else {
                this.handleShareRecordClick(fetchData.shareId, object.key)
              }
            } else {
              if (isBatchFields) {
                this.handleBatchClick(study, object.batchId)
              } else {
                this.handleRecordClick(study, object.key)
              }
            }
          }}
        >
          <div data-tip={value}>{value}</div>
        </Cell>
      </div>
    )
  }
}

/**
 * Header cell with caption and sorting feature.
 * If current sort type is {@link SortTypes.DESC} it adds symbol '↓' to end of caption.
 * If current sort type is {@link SortTypes.ASC} it adds symbol '↑' to end of caption.
 */
class SortHeaderCell extends Component {
  constructor(props) {
    super(props)

    this._onSortChange = this._onSortChange.bind(this)
  }

  render() {
    const { sortDir, children } = this.props
    return (
      <Cell>
        <Button
          onClick={this._onSortChange}
          style={{ background: 'none', width: '100%', height: 55 }}
        >
          {children} {sortDir ? (sortDir === SortTypes.DESC ? '↓' : '↑') : ''}
        </Button>
      </Cell>
    )
  }

  _onSortChange(e) {
    e.preventDefault()

    if (this.props.onSortChange) {
      let sortDir = SortTypes.DESC
      if (this.props.sortDir)
        sortDir =
          this.props.sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC

      this.props.onSortChange(this.props.columnKey, sortDir)
    }
  }
}

/**
 * Data wrapper for comfortable access to the sorting data.
 */
class DataListWrapper {
  constructor(indexMap, data) {
    this._indexMap = indexMap
    this._data = data
  }

  getSize() {
    return this._indexMap.length
  }

  getObjectAt(index) {
    return this._data[this._indexMap[index]]
  }
}

/**
 * Main component with table of records.
 *
 * Lifecycle of new component:
 * * _setFilter - create array with data and filter it;
 * * _setRecords - create wrapper for filtered data;
 * * _setSortedList - sorting list by some field;
 */

export default
@observer
class ViewComponent extends Component {
  /**
   * Model with list of variables.
   *
   * @type {ListModel|null}
   */
  variableListModel = null

  /**
   * Last version of records.
   * If new version has been changed we need recalculate sort indexes.
   *
   * @type {Number}
   */
  version = 0

  /**
   * Model with list of records.
   *
   * @type {ViewModel|null}
   */
  viewModel = null

  constructor(props) {
    super(props)

    this.viewModel = new StudyViewModel()
    this.viewModel._study = props.match.params.study
    this.viewModel.fetchData = props.fetchData

    this.variableListModel = new ListModel()
    this.variableListModel._study = props.match.params.study
    this.variableListModel._isBatchFields = props.isBatchFields
    this.variableListModel.fetchData = props.fetchData

    this.batchesModel = new BatchesModel()
    this.batchesModel.localKey = props.isBatchFields ? 'BF' : ''
    this.batchesModel.study = props.match.params.study
    this.batchesModel.fetchData = props.fetchData

    this.batchesVariableModel = new BatchesVariableModel()
    this.batchesVariableModel.study = props.match.params.study
    this.batchesVariableModel.fetchData = props.fetchData

    this.selectedRecordsStore = new RecordsSelectToDeleteStore(
      props.fetchData,
      props.isBatchFields
    )
    this.selectedRecordsStore.batchesModel = this.batchesModel

    let sortObj = localStorage.getItem(
      'StudyViewComponent-sort::' +
        props.match.params.study +
        '::' +
        (props.isBatchFields ? 'True' : 'False')
    )
    if (!sortObj) {
      sortObj = {
        columnSortDirection: {},
        columnSortKey: '',
      }
    } else {
      sortObj = JSON.parse(sortObj)
    }

    this.state = {
      ...sortObj,
      variableFilter: {},
    }

    this._onSortChange = this._onSortChange.bind(this)
    this._onFilterFieldChange = this._onFilterFieldChange.bind(this)
    this._onColumnReorderEndCallback = this._onColumnReorderEndCallback.bind(
      this
    )
    this.onExport = this.onExport.bind(this)
  }

  componentDidMount() {
    this.dataRecords = null
    this.viewModel.update()
    this.variableListModel.update()
    this.batchesModel.update()
    this.batchesVariableModel.update()
  }

  /**
   * Sort indexes.
   *
   * @param {String} columnKey Column key.
   * @param {String} sortDir Sort direction.
   * @private
   */
  _setSortedList(columnKey, sortDir) {
    let sortIndexes = this._defaultSortIndexes.slice()

    sortIndexes.sort((indexA, indexB) => {
      const objectA = this._dataList.getObjectAt(indexA)
      const objectB = this._dataList.getObjectAt(indexB)

      let valueA = objectA.values[columnKey]
      let valueB = objectB.values[columnKey]
      if (columnKey === 'Rec ID') {
        valueA = objectA['recordId']
        valueB = objectB['recordId']
      } else if (columnKey === 'Count') {
        valueA = objectA.count
        valueB = objectB.count
      } else if (columnKey === 'Record Time') {
        valueA = dateFormat(objectA.time, 'hh:MM:ss')
        valueB = dateFormat(objectB.time, 'hh:MM:ss')
      } else if (columnKey === 'Record Date') {
        valueA = objectA.time
        valueB = objectB.time
      } else if (columnKey === 'Batch') {
        const batchesItemA = this.batchesModel.batchesList.find(
          (item) => item.buid.toString() === objectA.batchId.toString()
        )
        const batchesItemB = this.batchesModel.batchesList.find(
          (item) => item.buid.toString() === objectB.batchId.toString()
        )

        valueA = batchesItemA.name
        valueB = batchesItemB.name
      }

      const valueANum = +valueA
      if (!isNaN(valueANum)) valueA = valueANum

      const valueBNum = +valueB
      if (!isNaN(valueBNum)) valueB = valueBNum

      let sortVal = 0
      if (valueA > valueB) {
        sortVal = 1
      }
      if (valueA < valueB) {
        sortVal = -1
      }
      if (sortVal !== 0 && sortDir === SortTypes.ASC) {
        sortVal *= -1
      }

      return sortVal
    })

    this.sortedDataList = new DataListWrapper(sortIndexes, this._dataList._data)
  }

  /**
   * Sets sorted list and save direction to state.
   *
   * @param {String} columnKey Column key.
   * @param {String} sortDir Sort direction.
   * @private
   */
  _onSortChange(columnKey, sortDir) {
    this._setSortedList(columnKey, sortDir)

    const sortObj = {
      columnSortKey: columnKey,
      columnSortDirection: {
        [columnKey]: sortDir,
      },
    }

    localStorage.setItem(
      'StudyViewComponent-sort::' +
        this.props.fetchData.study +
        '::' +
        (this.props.isBatchFields ? 'True' : 'False'),
      JSON.stringify(sortObj)
    )
    this.setState(sortObj)
  }

  /**
   * Handler of changing filter.
   *
   * @private
   */
  _onFilterFieldChange() {
    this._setFilter()

    this.setState({
      variableFilter: this.variableListModel.filter,
    })
  }

  /**
   * Filter data and set filter's object to state.
   *
   * @private
   */
  _setFilter() {
    const variableFilter = this.variableListModel.filter
    const variableKeysAll = Object.keys(variableFilter)
    const variableKeys = variableKeysAll.filter((item) => {
      return !!variableFilter[item].length
    })

    if (variableKeys.length === 0) {
      this._setRecords(this.dataRecords)
      return
    }

    const matchRecords = this.dataRecords.filter((item) => {
      const matchVariables = variableKeys.filter((variableKey) => {
        const curValue = item.values[variableKey] || ''
        return (
          curValue.trim().toLowerCase() ===
          variableFilter[variableKey].trim().toLowerCase()
        )
      })
      return matchVariables.length === variableKeys.length
    })

    this._setRecords(matchRecords)
  }

  /**
   * Creates default indexes for sorting. Creates wrapper for sorting.
   * Applies sorting.
   *
   * @param {{}} dataRecords Filtered data.
   * @private
   */
  _setRecords(dataRecords) {
    this._defaultSortIndexes = []
    for (let index = 0; index < dataRecords.length; index++) {
      this._defaultSortIndexes.push(index)
    }

    this._dataList = new DataListWrapper(this._defaultSortIndexes, dataRecords)
    if (this.state.columnSortKey)
      this._setSortedList(
        this.state.columnSortKey,
        this.state.columnSortDirection[this.state.columnSortKey]
      )
    else this.sortedDataList = this._dataList
  }

  /**
   * Returns columns order array.
   *
   * @returns {[]} List of columns.
   * @private
   */
  _getColumnOrder() {
    const variableSelected = this.variableListModel.selected
    const variableKeysAll = Object.keys(variableSelected)
    const columns = variableKeysAll.filter((item) => {
      return !!variableSelected[item]
    })

    return this.variableListModel.getOrderByColumns(columns)
  }

  /**
   * Changes order of columns.
   *
   * @param {Event} event Event object.
   * @private
   */
  _onColumnReorderEndCallback(event) {
    const columnOrderBase = this._getColumnOrder()

    const columnOrder = columnOrderBase.filter((columnKey) => {
      return columnKey !== event.reorderColumn
    })
    if (event.columnAfter) {
      const index = columnOrder.indexOf(event.columnAfter)
      columnOrder.splice(index, 0, event.reorderColumn)
    } else {
      columnOrder.push(event.reorderColumn)
    }

    this.variableListModel.columnOrder = columnOrder
  }

  onExport() {
    const fetchData = this.props.fetchData

    firebaseApp
      .database()
      .ref(
        `/studies/${fetchData.userId}/${fetchData.databaseId}/${fetchData.study}`
      )
      .once('value')
      .then((snapshot) => {
        const snapshotVal = snapshot.val()

        const name = `${
          this.props.isBatchFields
            ? 'Batches Data File'
            : 'Fish Records Data File'
        }-${snapshotVal.name}-${parseInt(snapshotVal.suid, 10).toString(
          16
        )}.csv`

        const data = this.sortedDataList
        let fields = this._getColumnOrder()

        let values = []

        const variables = this.variableListModel.variables
        fields = fields.map((field) => {
          const variable = variables.find((item) => item.name === field)
          return variable ? variable.displayedName : field
        })

        for (let i = 0; i < data.getSize(); i++) {
          const element = data.getObjectAt(i)
          const batchesItem = this.batchesModel.batchesList.find(
            (item) => item.buid.toString() === element.batchId.toString()
          )

          const valuesKeys = Object.keys(element['values'])
          const studyValues = {}
          valuesKeys.forEach((valuesKey) => {
            const variable = variables.find((item) => item.name === valuesKey)
            const name = variable ? variable.displayedName : valuesKey
            studyValues[name] = element['values'][valuesKey].toString()
          })

          values.push({
            ...studyValues,
            'Rec ID': element.recordId,
            'Study name': this.viewModel.studyData.name,
            SUID: this.viewModel.studyData.suid,
            Count: element.count,
            'Record Time': dateFormat(element.time, 'hh:MM:ss'),
            'Record Date': dateFormat(element.time, 'dd mmmm yyyy'),
            Batch: batchesItem.name,
          })
        }

        const result = parse(values, { fields })
        const csvContent = 'data:text/csv;charset=utf-8,' + result
        const encodedUri = encodeURI(csvContent)
        const link = document.createElement('a')

        link.setAttribute('href', encodedUri)
        link.setAttribute('download', name)
        link.click()
      })
  }

  render() {
    if (
      !this.viewModel.isLoaded ||
      !this.variableListModel.isLoaded ||
      !this.batchesModel.isLoaded ||
      !this.batchesVariableModel.isLoaded
    )
      return <CircularProgress />

    const records = this.viewModel.records

    this.dataRecords = records.map((r) => ({
      ...r,
      batchId: this.batchesModel.batchByRecord(r.recordId),
    }))

    const batchesRecords = this.batchesModel.recordsToShow()

    window.addTestRecord = (num) => {
      this.viewModel.addTestRecord(num)
    }
    this.dataRecords = this.dataRecords.filter((element) => {
      return batchesRecords.includes(element.key)
    })

    if (this.props.isBatchFields) {
      const batchVariablesKeys = Object.keys(
        this.batchesVariableModel.batchVariables
      )
      const variableListModelVariables = this.variableListModel.variables
      const batchesList = this.batchesModel._batchesList.slice()

      this.dataRecords = batchVariablesKeys
        .map((batchVariablesKey) => {
          const batchVariablesItem = this.batchesVariableModel.batchVariables[
            batchVariablesKey
          ]
          const batchVariablesItemKeys = Object.keys(batchVariablesItem)
          const batchVariablesResult = {}
          batchVariablesItemKeys.forEach((batchVariablesItemKey) => {
            /*
          1. above we get the id of all variables, which are the keys of a batchVariable entry
          2. find variables in the variables list with ids that match the keys from step 1
        */
            const variableItem = variableListModelVariables.find(
              (itemV) => itemV.key === batchVariablesItemKey.toString()
            )
            if (variableItem) {
              batchVariablesResult[variableItem.name] =
                batchVariablesItem[batchVariablesItemKey.toString()]
            }
          })

          // find the batch these variables are associated with
          const batchesItem = batchesList.find(
            (i) => i.buid.toString() === batchVariablesKey.toString()
          )

          if (batchesItem) {
            return {
              values: batchVariablesResult,
              recordId: batchesItem.name,
              batchId: batchesItem.buid,
            }
          }
          return {}
        })
        .filter((batchFields) => batchFields !== undefined)
    }

    // There is using computed property for update state.
    if (this.viewModel.version < 0) return ''
    if (this.variableListModel.version < 0) return ''
    if (this.batchesModel.version < 0) return ''

    if (this.viewModel.version !== this.version) {
      this.version = this.viewModel.version
    }

    const variableKeys = this._getColumnOrder()
    this._setFilter()

    const { ...props } = this.props

    const tableWidth = window.innerWidth - 50
    const columnWidth = Math.max(150, tableWidth / (variableKeys.length + 1))
    const headerRows = variableKeys.map((field) => {
      const variable = this.variableListModel.variables.find(
        (item) => item.name === field
      )
      const name = variable ? variable.displayedName : field
      return (
        <Column
          allowCellsRecycling={true}
          isReorderable={true}
          columnKey={field}
          header={
            <SortHeaderCell
              onSortChange={this._onSortChange}
              sortDir={this.state.columnSortDirection[field]}
            >
              {name}
            </SortHeaderCell>
          }
          fixed={false}
          cell={
            <TextCell
              data={this.sortedDataList}
              field={field}
              study={this.props.fetchData.study}
              fetchData={this.props.fetchData}
              studyData={this.viewModel.studyData}
              batchesList={this.batchesModel.batchesList}
              isBatchFields={this.props.isBatchFields}
            />
          }
          width={columnWidth}
          key={field}
        />
      )
    })

    let tableHeight = window.outerHeight - 310
    let notification = null

    if (!userModel.stripeCustomer.hasActiveAccount) {
      tableHeight -= 85
      notification = <FreeNotification />
    }

    return (
      <div>
        {!this.props.isBatchFields && (
          <FixedBox
            store={this.selectedRecordsStore}
            data={this.sortedDataList}
            fetchData={this.props.fetchData}
            isBatchFields={this.props.isBatchFields}
          />
        )}
        <ReactTooltip />
        <FilterComponent
          batchesModel={this.batchesModel}
          variableListModel={this.variableListModel}
          onFilterFieldChange={this._onFilterFieldChange}
          onExport={this.onExport}
          isBatchFields={this.props.isBatchFields}
          fetchData={this.props.fetchData}
          studyID={this.props.fetchData.study}
        />
        <Table
          touchScrollEnabled={true}
          rowsCount={this.sortedDataList.getSize()}
          rowHeight={50}
          headerHeight={65}
          width={tableWidth}
          height={tableHeight}
          onColumnReorderEndCallback={this._onColumnReorderEndCallback}
          isColumnReordering={false}
          {...props}
        >
          {!this.props.isBatchFields && this.props.fetchData.isShareEdit && (
            <Column
              header=""
              columnKey={'__select'}
              fixed={true}
              cell={
                <CheckboxCell
                  data={this.sortedDataList}
                  field="__select"
                  study={this.props.fetchData.study}
                  // fetchData={this.props.fetchData}
                  // isBatchFields={this.props.isBatchFields}
                  store={this.selectedRecordsStore}
                />
              }
              width={50}
              flexGrow={1}
            />
          )}
          {headerRows}
        </Table>
        {notification}
      </div>
    )
  }
}

class FreeNotification extends Component {
  handleClick() {
    history.push('/account')
  }

  render() {
    return (
      <Segment style={{ margin: '10px' }} inverted color="orange">
        Free Accounts have limited Collections, Studies and Records. Upgrade to
        a Paid Account to see all of your data.
        <Button style={{ marginLeft: '12px' }} onClick={this.handleClick}>
          Upgrade now!
        </Button>
      </Segment>
    )
  }
}
