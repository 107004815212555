import React, { Component } from 'react'
import { parse } from 'json2csv'
import { Header, Table, Label, Button, Modal, Icon } from 'semantic-ui-react'
import ListModel from 'src/Models/Variable/ListStudyModel'
import CircularProgress from '@material-ui/core/CircularProgress'
import Snackbar from '@material-ui/core/Snackbar'
import { observer } from 'mobx-react'
import { Input, Select } from 'semantic-ui-react'
import { firebaseApp } from 'src/config/firebase'

class DeleteComponent extends Component {
  state = {
    isModalOpen: false,
    isDeleting: false,
  }

  showModal = () => this.setState({ isModalOpen: true })
  hideModal = () => this.setState({ isModalOpen: false })

  handleDelete = () => {
    const { fetchData, variableKey, variableListModel } = this.props

    this.setState({
      isModalOpen: false,
      isDeleting: true,
    })

    firebaseApp
      .database()
      .ref(
        `/variables/${fetchData.userId}/${fetchData.databaseId}/${fetchData.study}/${variableKey}`
      )
      .remove()
      .then(() => {
        variableListModel.update()
      })
  }

  render() {
    return (
      <div>
        <Modal
          trigger={
            <Button
              disabled={!this.props.fetchData.isShareEdit}
              loading={this.state.isDeleting}
              color="red"
              onClick={this.showModal}
            >
              Delete
            </Button>
          }
          open={this.state.isModalOpen}
          onClose={this.hideModal}
          size="small"
        >
          <Header icon="trash" content="Delete" />
          <Modal.Content>
            <h3>Are you sure you want to delete this field?</h3>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.hideModal}>Close</Button>
            <Button color="red" onClick={this.handleDelete} inverted>
              <Icon name="trash" /> Delete
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    )
  }
}

class RowComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isSaving: false,
    }
  }

  render() {
    const {
      variableItem,
      saveToData,
      variableKey,
      fetchData,
      variableListModel,
    } = this.props
    const valueCurrent = variableItem['value']
    saveToData(variableKey, valueCurrent)

    let input
    if (variableItem.selections) {
      let options = variableItem.selections.map((value) => {
        return { text: value, value: value }
      })

      options.unshift({ text: 'Select option', value: '' })

      input = (
        <Select
          options={options}
          onChange={(event, select) => {
            if (valueCurrent !== select.value) {
              saveToData(variableKey, select.value)
            }
          }}
          defaultValue={valueCurrent}
          placeholder="Select option"
          disabled={!fetchData.isShareEdit}
          fluid
        />
      )
    } else {
      input = (
        <Input
          disabled={!fetchData.isShareEdit}
          defaultValue={valueCurrent}
          onChange={(event, inputProps) => {
            saveToData(variableKey, inputProps.value)
          }}
          fluid
        />
      )
    }

    return (
      <Table.Row>
        <Table.Cell width="1">
          <Label color={variableItem.variableType.toLowerCase()} size="large">
            {variableItem.inputType.substr(0, 3)}
          </Label>
        </Table.Cell>
        <Table.Cell width="4">
          <Header as="h4">
            <Header.Content>{variableItem.displayedName}</Header.Content>
          </Header>
        </Table.Cell>
        <Table.Cell width="8">{input}</Table.Cell>
        <Table.Cell width="1">
          <DeleteComponent
            fetchData={fetchData}
            variableKey={variableKey}
            variableListModel={variableListModel}
          />
        </Table.Cell>
      </Table.Row>
    )
  }
}

export default
@observer
class ViewComponent extends Component {
  constructor(props) {
    super(props)

    this.variableListModel = new ListModel()
    this.variableListModel._study = props.match.params.study
    this.variableListModel.fetchData = props.fetchData

    this.saveToData = this.saveToData.bind(this)
    this.getFromData = this.getFromData.bind(this)
    this.saveAllData = this.saveAllData.bind(this)

    this._data = {}

    this.state = {
      isAlert: false,
    }
  }

  handleAlertOpen = () => {
    this.setState({
      isAlert: true,
    })
  }

  handleAlertClose = () => {
    this.setState({
      isAlert: false,
    })
  }

  onExport() {
    const variables = this.variableListModel.variables
    const fields = variables.map((item) => item.name)
    const data = {}
    variables.forEach((item) => {
      data[item.name] = item.value.toString()
    })

    const fetchData = this.props.fetchData
    firebaseApp
      .database()
      .ref(
        `/studies/${fetchData.userId}/${fetchData.databaseId}/${this.props.match.params.study}`
      )
      .once('value')
      .then((snapshot) => {
        const val = snapshot.val()

        const name = `Fish Study Settings Data File-${val.name}-${parseInt(
          val.suid,
          10
        ).toString(16)}.csv`

        const result = parse([data], { fields })
        const csvContent = 'data:text/csv;charset=utf-8,' + result
        const encodedUri = encodeURI(csvContent)
        const link = document.createElement('a')

        link.setAttribute('href', encodedUri)
        link.setAttribute('download', name)
        link.click()
      })
  }

  componentDidMount() {
    this.variableListModel.update()
  }

  saveAllData() {
    const fetchData = this.props.fetchData
    const dataKeys = Object.keys(this._data)

    const promises = []
    dataKeys.forEach((key) => {
      promises.push(
        firebaseApp
          .database()
          .ref(
            `/variables/${fetchData.userId}/${fetchData.databaseId}/${fetchData.study}/` +
              key
          )
          .update({ value: this._data[key] })
      )
    })

    Promise.all(promises).then(this.handleAlertOpen)
  }

  saveToData(key, value) {
    this._data[key] = value || ''
  }

  getFromData(key) {
    return this._data[key]
  }

  render() {
    if (!this.variableListModel.isLoaded) return <CircularProgress />

    const studyID = this.props.match.params.study

    let variables = {}

    for (let i = 0; i < this.variableListModel.variables.length; i++) {
      const element = this.variableListModel.variables[i]
      variables[element.name] = element
    }

    const variableKeys = Object.keys(variables)

    const rows = variableKeys.map((key) => {
      return (
        <RowComponent
          key={key}
          variableItem={variables[key]}
          variableKey={variables[key]['key']}
          studyID={studyID}
          handleAlertOpen={this.handleAlertOpen}
          saveToData={this.saveToData}
          getFromData={this.getFromData}
          fetchData={this.props.fetchData}
          variableListModel={this.variableListModel}
        />
      )
    })

    return (
      <div style={{ margin: 24 + 'px' }}>
        <Snackbar
          open={this.state.isAlert}
          message="Changes have been saved"
          autoHideDuration={4000}
          onClose={this.handleAlertClose}
        />
        {this.props.fetchData.isShareEdit && (
          <Button
            icon="save"
            content="Save all fields"
            color="blue"
            labelPosition="right"
            onClick={this.saveAllData}
          />
        )}
        <Button
          icon="download"
          content="Export to CSV"
          color="green"
          labelPosition="right"
          onClick={this.onExport.bind(this)}
        />
        <Table basic="very" celled collapsing>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Value</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>{rows}</Table.Body>
        </Table>
      </div>
    )
  }
}
